<template>
  <div class="pay-wrap">

  </div>

</template>

<script>
  export default {
    name: 'pay',
    created() {
      // location.href = 'tel://' + '073182297060'
   },
    methods: {

    }
  }
</script>
<style lang="scss" scoped>
  .pay-wrap {

  }
</style>
